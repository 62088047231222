import { all, call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '../../../repositories/api'

import { requestFailed } from '../error/action'
import { integrationSuccess, integrationDisconnectSuccess } from './actions'

export function* integrationRequest({ payload }) {
  try {
    const { storeId, token, data, provider } = payload
    const response = yield call(
      api.axios.put,
      `integration/${provider}/store/${storeId}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 100000
      }
    )
    yield put(integrationSuccess(response.data))
    toast.info(
      `Integração ${data.userDict ? 'atualizada ' : 'realizada '}com sucesso`
    )
  } catch (e) {
    console.log('Integration Error:', e.response?.data) // Log para debug

    // Verificar se há uma mensagem específica no erro
    const errorMessage = e.response?.data?.error?.message

    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            // Usar a mensagem específica como mensagem amigável, se disponível
            friendlyMsg:
              errorMessage ||
              e.response.data?.error?.friendlyMsg ||
              'Servidor fora do ar.',
            message: errorMessage || null
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? {
                  error: {
                    // Usar a mensagem específica como mensagem amigável, se disponível
                    friendlyMsg:
                      errorMessage ||
                      e.response.data?.error?.friendlyMsg ||
                      'Alguma coisa deu errado...',
                    message: errorMessage || null
                  }
                }
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* integrationDisconnectRequest({ payload }) {
  try {
    const { provider, storeId, token } = payload
    const response = yield call(
      api.axios.delete,
      `integration/${provider}/store/${storeId}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
    yield put(integrationDisconnectSuccess(response.data))
    toast.info('Integração removida com sucesso')
  } catch (e) {
    console.log('Integration Error:', e.response?.data) // Log para debug

    // Verificar se há uma mensagem específica no erro
    const errorMessage = e.response?.data?.error?.message

    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            // Usar a mensagem específica como mensagem amigável, se disponível
            friendlyMsg:
              errorMessage ||
              e.response.data?.error?.friendlyMsg ||
              'Servidor fora do ar.',
            message: errorMessage || null
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? {
                  error: {
                    friendlyMsg:
                      errorMessage ||
                      e.response.data?.error?.friendlyMsg ||
                      'Alguma coisa deu errado...',
                    message: errorMessage || null
                  }
                }
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export default all([
  takeLatest('@integration/REQUEST', integrationRequest),
  takeLatest('@integration/DISCONNECT_REQUEST', integrationDisconnectRequest)
])
