import { call, put, all, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '../../../repositories/api'

import {
  activeStorylineRequest,
  createNewStorylineSuccess,
  deleteStorylineSuccess,
  getActiveStorylinesSuccess,
  getDraftStorylinesSuccess,
  getFinishedStorylinesSuccess,
  getStoriesFromStorylineSuccess,
  linkStoriesToStorylineRequest,
  updateStorylineSettingsAvatarSuccess,
  updateStorylineNameSuccess,
  updateStorylineTagAvatarRequest,
  deleteStorylineTagSuccess,
  updateStorylineTagSuccess,
  createStorylineTagSuccess,
  updateStorylineTagAvatarSuccess
} from './actions'
import { requestFailed } from '../error/action'
import { toIsoStringLocalTz } from 'utils/dateFunctions'
import { browserHistory } from 'App'

export function* getActiveStorylines({ payload }) {
  try {
    const { userId, token } = payload
    const response = yield call(
      api.axios.get,
      `/storyline/user/${userId}/active`,
      {
        params: {
          currentDate: toIsoStringLocalTz(new Date())
        },
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    yield put(getActiveStorylinesSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* getFinishedStorylines({ payload }) {
  try {
    const { userId, token, page, limit } = payload
    const response = yield call(
      api.axios.get,
      `/storyline/user/${userId}/expired`,
      {
        params: {
          currentDate: toIsoStringLocalTz(new Date()),
          limit,
          page
        },
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    const finishedStorylines = response.data.items
    const metadata = response.data.metadata

    yield put(getFinishedStorylinesSuccess(finishedStorylines, metadata))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* getDraftStorylines({ payload }) {
  try {
    const { userId, token } = payload
    const response = yield call(
      api.axios.get,
      `/storyline/user/${userId}/draft`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    yield put(getDraftStorylinesSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* createNewStoryline({ payload }) {
  try {
    const { newStoryline, files, links, token } = payload
    const { tagIcon, ...storylineBody } = newStoryline

    const response = yield call(api.axios.post, '/storyline', storylineBody, {
      headers: { Authorization: `Bearer ${token}` }
    })

    if (tagIcon instanceof FormData && tagIcon.has('thumbnail')) {
      const thumbnailResponse = yield call(
        api.axios.patch,
        `/storyline/${response.data._id}/upload-thumbnail`,
        tagIcon,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )

      yield put(
        createNewStorylineSuccess({
          ...response.data,
          totalStores: storylineBody.visibilitySettings.storeIds.length,
          photo: thumbnailResponse.data.photo
        })
      )
    } else {
      yield put(
        createNewStorylineSuccess({
          ...response.data,
          totalStores: storylineBody.visibilitySettings.storeIds.length
        })
      )
    }

    yield put(
      linkStoriesToStorylineRequest(files, links, response.data._id, token)
    )
  } catch (e) {
    toast.error('Não foi possível criar o comunicado')
    browserHistory.push('/storylines')

    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* activeStoryline({ payload }) {
  try {
    const { storylineId, token } = payload
    yield call(
      api.axios.patch,
      `/storyline/${storylineId}/activate`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
    toast.success('Comunicado criado')
    browserHistory.push('/storylines')
  } catch (e) {
    toast.error('Não foi possível criar o comunicado')
    browserHistory.push('/storylines')

    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* linkStoriesToStoryline({ payload }) {
  try {
    const { files, links, storylineId, token } = payload

    const response = yield all(
      Object.entries(files).map(([key, value], index) => {
        return call(
          api.axios.post,
          '/story',
          {
            index,
            storylineId,
            link: links[key],
            mimetype: value.type,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
            timeout: 60000,
          },
        )
      })
    )

    const policies = response.map(r => r.data)

    yield all(
      Object.values(files).map((file, index) => {
        const { url, fields } = policies[index]

        const formData = new FormData()

        for (const field in fields) {
          formData.append(field, fields[field])
        }

        formData.append('file', file)

        return call(function* () {
          const response = yield call(fetch, url, {
            method: 'post',
            body: formData,
          })

          if (!response.ok) {
            throw new Error('Nao foi possivel fazer o upload')
          }

          return response
        })
      })
    )

    yield put(activeStorylineRequest(storylineId, token))
  } catch (e) {
    toast.error('Não foi possível criar o comunicado')
    browserHistory.push('/storylines')

    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* deleteStoryline({ payload }) {
  try {
    const { storylineId, token } = payload
    yield call(api.axios.delete, `/storyline/${storylineId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    toast.success('Comunicado deletado com sucesso.')
    yield put(deleteStorylineSuccess(storylineId))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else if (e.code === 'ECONNABORTED') {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Internet instável, não foi possível conectar.'
          }
        })
      )
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
        )
      )
    }
  }
}

export function* getStoriesFromStoryline({ payload }) {
  try {
    const { storylineId, token } = payload
    const response = yield call(
      api.axios.get,
      `/story/storyline/${storylineId}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    yield put(getStoriesFromStorylineSuccess(storylineId, response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* updateStorylineName({ payload }) {
  try {
    const { tagId, tagIcon, newTagName, companyId, token } = payload
    const response = yield call(
      api.axios.patch,
      `/company/${companyId}/tag/${tagId}`,
      {
        name: newTagName
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    if (tagIcon instanceof FormData) {
      yield put(
        updateStorylineTagAvatarRequest(tagId, tagIcon, companyId, token)
      )
    } else {
      toast.success('Configurações alteradas com sucesso.')
    }

    yield put(updateStorylineNameSuccess(response.data.name))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* createStorylineTag({ payload }) {
  try {
    const { tagName, tagIcon, companyId, token } = payload
    const response = yield call(
      api.axios.post,
      `/company/${companyId}/tag`,
      {
        name: tagName
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    if (tagIcon instanceof FormData && tagIcon.has('photo')) {
      yield put(
        updateStorylineTagAvatarRequest(
          response.data.tagId,
          tagIcon,
          companyId,
          token
        )
      )
    } else {
      toast.success('Configurações alteradas com sucesso.')
    }

    yield put(
      createStorylineTagSuccess({
        _id: response.data.tagId,
        name: tagName,
        photo: {
          url: null
        }
      })
    )
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* updateStorylineSettingsAvatar({ payload }) {
  try {
    const { newStorylineSettingsAvatar, companyId, token } = payload
    const response = yield call(
      api.axios.patch,
      `/company/${companyId}/upload`,
      newStorylineSettingsAvatar,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    toast.success('Avatar alterado com sucesso.')
    yield put(updateStorylineSettingsAvatarSuccess(response.data.photo.url))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* updateStorylineTagAvatar({ payload }) {
  try {
    const { tagId, tagIcon, companyId, token } = payload
    const response = yield call(
      api.axios.patch,
      `/company/${companyId}/tag/${tagId}/upload`,
      tagIcon,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    toast.success('Alterações feitas com sucesso.')
    yield put(updateStorylineTagAvatarSuccess(response.data))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* updateStorylineTag({ payload }) {
  try {
    const { tagId, tagIcon, newTagName, companyId, token } = payload
    yield call(
      api.axios.patch,
      `/company/${companyId}/tag/${tagId}`,
      {
        name: newTagName
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    if (tagIcon instanceof FormData && tagIcon.has('photo')) {
      yield put(
        updateStorylineTagAvatarRequest(tagId, tagIcon, companyId, token)
      )
    } else {
      toast.success('Configurações alteradas com sucesso.')
    }

    yield put(
      updateStorylineTagSuccess({
        _id: tagId,
        name: newTagName
      })
    )
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                  error: {
                    friendlyMsg: 'Alguma coisa deu errado...',
                    err: e
                  }
                }
          )
        )
      }
    }
  }
}

export function* deleteStorylineTag({ payload }) {
  try {
    const { tagId, companyId, token } = payload

    yield call(api.axios.delete, `/company/${companyId}/tag/${tagId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    toast.success('Tag deletada com sucesso.')
    yield put(deleteStorylineTagSuccess(tagId))
  } catch (e) {
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg:
              e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else if (e.code === 'ECONNABORTED') {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: 'Internet instável, não foi possível conectar.'
          }
        })
      )
    } else {
      yield put(
        requestFailed(
          e.response && e.response.data
            ? e.response.data
            : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
        )
      )
    }
  }
}

export default all([
  takeLatest('@storylines/GET_ACTIVE_STORYLINES_REQUEST', getActiveStorylines),
  takeLatest(
    '@storylines/GET_FINISHED_STORYLINES_REQUEST',
    getFinishedStorylines
  ),
  takeLatest('@storylines/GET_DRAFT_STORYLINES_REQUEST', getDraftStorylines),
  takeLatest('@storylines/CREATE_NEW_STORYLINE_REQUEST', createNewStoryline),
  takeLatest('@storylines/ACTIVE_STORYLINE_REQUEST', activeStoryline),
  takeLatest(
    '@storylines/LINK_STORIES_TO_STORYLINE_REQUEST',
    linkStoriesToStoryline
  ),
  takeLatest('@storylines/DELETE_STORYLINE_REQUEST', deleteStoryline),
  takeLatest(
    '@storylines/GET_STORIES_FROM_STORYLINE_REQUEST',
    getStoriesFromStoryline
  ),
  takeLatest('@storylines/UPDATE_STORYLINE_NAME_REQUEST', updateStorylineName),
  takeLatest(
    '@storylines/UPDATE_STORYLINE_SETTINGS_AVATAR_REQUEST',
    updateStorylineSettingsAvatar
  ),
  takeLatest('@storylines/CREATE_STORYLINE_TAG_REQUEST', createStorylineTag),
  takeLatest('@storylines/UPDATE_STORYLINE_TAG_REQUEST', updateStorylineTag),
  takeLatest(
    '@storylines/UPDATE_STORYLINE_TAG_AVATAR_REQUEST',
    updateStorylineTagAvatar
  ),
  takeLatest('@storylines/DELETE_STORYLINE_TAG_REQUEST', deleteStorylineTag)
])
