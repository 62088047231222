// formatPriceShort, tem que receber o valor formatado com , substituído por .

export const formatPriceShort = (value: number) => {
  const isRealNumber =
    typeof value === 'number' && value !== Infinity && !isNaN(value)

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 1
  })
    .format(isRealNumber ? value : 0)
    .replace(',', '.')
}

export const formatPrice = (value: number) => {
  const isRealNumber =
    typeof value === 'number' && value !== Infinity && !isNaN(value)

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(isRealNumber ? value : 0)
}

export const formatToPercentage = (value: number) => {
  const isRealNumber =
    typeof value === 'number' && value !== Infinity && !isNaN(value)

  return isRealNumber ? `${value.toFixed(2).replace('.', ',')}%` : '0,00%'
}

export const formatDecimalToPercentage = (value: number) => {
  return formatToPercentage(value * 100)
}

export const safeDivision = (value: number) => {
  const isRealNumber =
    typeof value === 'number' && value !== Infinity && !isNaN(value)

  return isRealNumber ? value : 0
}
