import { useState } from 'react'

import { Backdrop, Modal } from '@material-ui/core'
import {
  DeleteOutline as DeleteOutlineIcon,
  Person as PersonIcon,
  Storefront as StorefrontIcon,
  Create as CreateIcon,
  Delete as DeleteIcon
} from '@material-ui/icons'

import Tooltip from 'components/Tooltip'
import PromptModal from '../../../../../../PromptModal'
import { formatPrice, formatDecimalToPercentage } from 'utils/format'
import { IMonthGoal } from 'store/modules/goal/types'

import {
  Container,
  ErrorOutlineIcon,
  GoalIcon,
  GoalPercentageCommission,
  IconsContainer,
  PromptModalSpanContainer,
  TooltipContent
} from './styles'
import { getMonthGoalTooltipInfos } from 'utils/getMonthGoalTooltipInfos'

interface TableItemProps {
  onDelete: (index: number) => void
  onEdit: () => void
  index: number
  goal: IMonthGoal
}

export const TableItem = ({
  onDelete,
  onEdit,
  index,
  goal
}: TableItemProps) => {
  const [isShowModal, setIsShowModal] = useState(false)

  const { sumCommission } = goal.initialCommission

  const monthGoalTooltipInfos = getMonthGoalTooltipInfos(goal.initialCommission)

  const isGoalBasedOnUserSales = goal.type === 'user'
  const goalSalesType = `${formatDecimalToPercentage(goal.commission)} ${
    isGoalBasedOnUserSales ? 'de suas vendas' : 'do faturamento da loja'
  }`

  const goalHasBonus = goal.bonus > 0
  const goalBonus = goalHasBonus
    ? goal.bonusPercentage
      ? formatDecimalToPercentage(goal.bonus)
      : formatPrice(goal.bonus)
    : 'Sem bônus'

  const isFirstGoal = index === 0

  return (
    <>
      <Container>
        <td>
          <GoalIcon
            isFirstGoal={isFirstGoal}
            data-tip
            data-for={`goal-${index + 1}-tooltip`}
          >
            <span>{index + 1}</span>
            <Tooltip id='goal-1-tooltip' content={<p>Meta Principal</p>} />
          </GoalIcon>
        </td>
        <td>
          <strong>{goal.name}</strong>
        </td>
        <td>{formatPrice(goal.value)}</td>
        <td>
          <GoalPercentageCommission>
            <span>{formatDecimalToPercentage(goal.commission)}</span>
            {isGoalBasedOnUserSales ? (
              <PersonIcon data-tip data-for={`${index}-user-tooltip`} />
            ) : (
              <StorefrontIcon data-tip data-for={`${index}-store-tooltip`} />
            )}
            <Tooltip
              id={`${index}-${goal.type}-tooltip`}
              place='right'
              content={<p>{goalSalesType}</p>}
            />
          </GoalPercentageCommission>
        </td>
        <td style={{ opacity: goalHasBonus ? 1 : 0.5 }}>{goalBonus}</td>
        <td id='value' className='commission-cell'>
          <Tooltip
            id={`month-goal-total-${goal._id}`}
            place='bottom'
            content={
              <TooltipContent>
                <strong>
                  Remuneração Total <br />
                </strong>
                {Object.entries(monthGoalTooltipInfos).map(([key, value]) => (
                  <p key={key}>
                    <strong>{key}: </strong> {value}
                    <br />
                  </p>
                ))}
                <p>
                  <strong>Total: </strong>
                  {formatPrice(sumCommission ?? 0)}
                </p>
              </TooltipContent>
            }
          />
          {formatPrice(sumCommission ?? 0)}

          <ErrorOutlineIcon
            data-tip
            data-for={`month-goal-total-${goal._id}`}
          />
        </td>
        <td>
          <IconsContainer>
            <button onClick={onEdit} type='button'>
              <CreateIcon />
            </button>
            <button onClick={() => setIsShowModal(true)} type='button'>
              <DeleteIcon />
            </button>
          </IconsContainer>
        </td>

        {isShowModal && (
          <Modal
            open={isShowModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <PromptModal
              isAlert
              icon={<DeleteOutlineIcon fontSize='large' />}
              onClose={() => setIsShowModal(false)}
              leftTitle='Cancelar'
              onLeft={() => setIsShowModal(false)}
              rightTitle='Remover meta'
              onRight={() => {
                onDelete(index)
                setIsShowModal(false)
              }}
            >
              <PromptModalSpanContainer>
                Deseja remover a meta <br />
                <strong>{goal.name}</strong>?
              </PromptModalSpanContainer>
            </PromptModal>
          </Modal>
        )}
      </Container>
    </>
  )
}
