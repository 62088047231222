import { useState } from 'react'

import * as XLSX from 'xlsx'
import { getYear, lastDayOfMonth, startOfMonth, subMonths } from 'date-fns'

// Material UI
import {
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'

import { CloseTwoTone, CloudDownloadTwoTone } from '@material-ui/icons'

import { SheetButton, SheetModal } from 'views/Reports/components/styles'
import api from 'repositories/api'
import { useSelector } from 'react-redux'
import { safeDivision } from 'utils/format'
import { CircularProgress } from '@mui/material'
import { toast } from 'react-toastify'
import { deepClone } from 'utils/deepClone'
import { DateTime } from 'luxon'

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 150
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
}

export const ExportSpreadSheetModal = ({ isOpen, onClose }) => {
  const { user } = useSelector(state => state.user)

  const [selectedMonth, setSelectedMonth] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const currentMonth = new Date().setHours(0, 0, 0, 0)

  const months = [
    new Date(currentMonth).toISOString(),
    subMonths(currentMonth, 1).toISOString(),
    subMonths(currentMonth, 2).toISOString(),
    subMonths(currentMonth, 3).toISOString(),
    subMonths(currentMonth, 4).toISOString(),
    subMonths(currentMonth, 5).toISOString(),
    subMonths(currentMonth, 6).toISOString(),
    subMonths(currentMonth, 7).toISOString(),
    subMonths(currentMonth, 8).toISOString(),
    subMonths(currentMonth, 9).toISOString(),
    subMonths(currentMonth, 10).toISOString(),
    subMonths(currentMonth, 11).toISOString(),
    subMonths(currentMonth, 12).toISOString()
  ]

  const getMonthName = stringDate => {
    const dateMonth = new Date(stringDate).toLocaleDateString('pt-BR', {
      month: 'long'
    })

    return `${dateMonth[0].toUpperCase()}${dateMonth.slice(1)}`
  }

  const closeModal = () => {
    setSelectedMonth('')
    onClose()
  }

  const handleExportAllSpreadSheet = async () => {
    if (!selectedMonth) {
      toast.error('Selecione o mês que quer exportar')
    } else {
      try {
        setIsLoading(true)
        const startMonth = startOfMonth(
          new Date(selectedMonth).setHours(0, 0, 0, 0)
        ).toISOString()
        const endMonth = lastDayOfMonth(new Date(selectedMonth)).toISOString()
        const monthName = getMonthName(selectedMonth)

        const response = await api.axios.get(
          `report/user/${user._id}/export?from=${startMonth}&to=${endMonth}`,
          {
            timeout: 160000
          }
        )

        const formatTableStoreDataToExcel = storeToBeFormatted => [
          'Loja',
          '-', // CPF
          storeToBeFormatted.total.sold ?? 0, // Faturamento
          storeToBeFormatted.projection.sold ?? 0, // Projeção de Faturamento
          storeToBeFormatted.metric.acDayGoal ?? 0, // Meta Principal
          safeDivision(
            storeToBeFormatted.total.sold / storeToBeFormatted.metric.acDayGoal
          ), // % da Meta
          '-', // Meta Atual
          '-', // Piso
          '-', // Complemento
          '-', // % de Comissão Base
          '-', // Comissão Base
          '-', // % de Comissão
          '-', // Comissão
          '-', // DSR
          '-', // Valor DSR
          '-', // Bônus
          '-', // Valor Bônus
          '-', // Remuneração Total
          '-', // Projeção de Remuneração
          storeToBeFormatted.total.sales ?? 0, // N˚ Vendas
          safeDivision(
            storeToBeFormatted.total.sold / storeToBeFormatted.total.sales
          ), // Ticket Médio
          safeDivision(
            storeToBeFormatted.total.items / storeToBeFormatted.total.sales
          ), // Peça/Venda
          safeDivision(
            storeToBeFormatted.total.sold / storeToBeFormatted.total.items
          ), // Preço Médio
          storeToBeFormatted.total.items ?? 0 // N˚ Peças
        ]

        const formatTableSellersDataToExcel = sellerToBeFormatted => [
          sellerToBeFormatted.active
            ? sellerToBeFormatted.name.complete
            : `(INATIVO) ${sellerToBeFormatted.name.complete}`, // Nome do vendedor
          sellerToBeFormatted.cpf ?? 'Não informado', // CPF
          sellerToBeFormatted.total.sold ?? 0, // Faturamento do Vendedor
          sellerToBeFormatted.projection.sold ?? 0, // Projeção de Faturamento
          sellerToBeFormatted.metric.acDayGoal ?? 0, // Meta Principal do vendedor
          safeDivision(
            sellerToBeFormatted.total.sold /
              sellerToBeFormatted.metric.acDayGoal
          ), // % da Meta
          sellerToBeFormatted.projection?.goal?.name || '-', // Meta atual
          sellerToBeFormatted.commission?.salary ?? '-', // Piso
          sellerToBeFormatted.commission?.complement ?? '-', // Complemento
          sellerToBeFormatted.commission?.fixedCommission?.percentage ?? '-', // % de Comissão Base
          sellerToBeFormatted.commission?.fixedCommission?.value ?? '-', // Comissão Base
          sellerToBeFormatted.commission?.commission?.percentage ?? '-', // % de Comissão
          sellerToBeFormatted.commission?.commission?.value ?? '-', // Comissão
          sellerToBeFormatted.commission?.dsrDays ?? '-', // DSR
          sellerToBeFormatted.commission?.dsr ?? '-', // Valor DSR
          sellerToBeFormatted.commission?.bonus?.percentage ?? '-', // Bônus
          sellerToBeFormatted.commission?.bonus?.value ?? '-', // Valor Bônus
          sellerToBeFormatted.commission?.sumCommission ?? '-', //  Remuneração Total
          sellerToBeFormatted.projection?.commission?.sumCommission ?? '-', // Projeção de Remuneração
          sellerToBeFormatted.total?.sales ?? 0, // N˚ Vendas
          safeDivision(
            sellerToBeFormatted.total.sold / sellerToBeFormatted.total.sales
          ), // Ticket Médio
          safeDivision(
            sellerToBeFormatted.total.items / sellerToBeFormatted.total.sales
          ), // Peça/Venda
          safeDivision(
            sellerToBeFormatted.total.sold / sellerToBeFormatted.total.items
          ), // Preço Médio
          sellerToBeFormatted.total.items ?? 0 // N˚ Peças
        ]

        const tableHeaders = [
          'Nome',
          'CPF',
          'Faturamento',
          'Projeção de Faturamento',
          'Meta Principal',
          '% da Meta',
          'Meta Atual',
          'Piso',
          'Complemento',
          '% de Comissão Base',
          'Comissão Base',
          '% de Comissão',
          'Comissão',
          'DSR',
          'Valor DSR',
          'Bônus',
          'Valor Bônus',
          'Remuneração Total',
          'Projeção de Remuneração',
          'N˚ Vendas',
          'Ticket Médio',
          'Peça/Venda',
          'Preço Médio',
          'N˚ Peças'
        ]

        const wb = XLSX.utils.book_new()

        const reservedNames = []
        const allStoresTabSheet = []

        let sellersCount = 0
        let storesCount = 0
        let indexCount = 0
        let hasAddedHeaderLoja = false

        let columnsToRemoveAllStores = [
          'Piso',
          'Complemento',
          '% de Comissão Base',
          'Comissão Base',
          '% de Comissão',
          'Comissão',
          'DSR',
          'Valor DSR',
          'Bônus',
          'Valor Bônus',
          'Remuneração Total',
          'Projeção de Remuneração'
        ]

        const fillObjectGaps = ws => {
          const obj = { ...ws }
          const ref = ws['!ref']

          delete obj['!ref']

          const keys = Object.keys(obj)
          const groupedKeys = {}

          keys.forEach(key => {
            const letter = key[0] // Extrai a letra (A, B, C, etc.)
            const number = parseInt(key.slice(1)) // Extrai o número

            if (!groupedKeys[number]) {
              groupedKeys[number] = []
            }
            groupedKeys[number].push(letter)
          })

          // Step 2: Organiza os números e letras
          const sortedGroups = Object.entries(groupedKeys).sort(
            ([num1], [num2]) => num1 - num2 // Ordena pelo número das colunas
          )

          // Step 3: Cria um novo objeto sem gaps
          const newObject = {}
          sortedGroups.forEach(([number, letters]) => {
            letters.sort((a, b) => a.localeCompare(b))
            letters.forEach((_, index) => {
              const newLetter = String.fromCharCode(65 + index) // Letras sequenciais começando em 'A'
              const oldKey = `${_}${number}` // Key original (ex., A1, B2, etc.)
              const newKey = `${newLetter}${number}` // Nova key com letras sequenciais, mas com o mesmo número
              newObject[newKey] = obj[oldKey] //Move o objeto pra nova key
            })
          })

          return {
            ['!ref']: ref,
            ...newObject
          }
        }

        response.data?.forEach(
          ({ name, store, sellers, otherInputs }, index) => {
            // Caso seja loja Oscar
            let oscarTableStoreData = []
            let oscarTableUsersData = []
            let columnsToRemove = [
              'Piso',
              'Complemento',
              '% de Comissão Base',
              'Comissão Base',
              '% de Comissão',
              'Comissão',
              'DSR',
              'Valor DSR',
              'Bônus',
              'Valor Bônus',
              'Remuneração Total',
              'Projeção de Remuneração'
            ]

            const checkColumnsToRemove = seller => {
              const {
                complement,
                dsr,
                dsrDays,
                salary,
                bonus,
                fixedCommission,
                commission,
                sumCommission
              } = seller.commission

              const conditionToRemoveColumnItemsMap = {
                Piso: !!salary,
                Complemento: !!complement,
                '% de Comissão Base': !!fixedCommission?.percentage,
                'Comissão Base': !!fixedCommission?.value,
                '% de Comissão': !!commission?.percentage,
                Comissão: !!commission?.value,
                DSR: !!dsrDays,
                'Valor DSR': !!dsr,
                Bônus: !!bonus?.percentage,
                'Valor Bônus': !!bonus?.value,
                'Remuneração Total': !!sumCommission,
                'Projeção de Remuneração':
                  !!seller.projection?.commission?.sumCommission
              }

              const aux = []
              // Filter out columns where the condition is true
              columnsToRemove.forEach(column => {
                if (conditionToRemoveColumnItemsMap[column]) {
                  aux.push(column)
                }
              })

              columnsToRemove = columnsToRemove.filter(
                col => !aux.includes(col)
              )
              aux.splice(0)

              // Filter out columns where the condition is true (all stores)
              columnsToRemoveAllStores.forEach(column => {
                if (conditionToRemoveColumnItemsMap[column]) {
                  aux.push(column)
                }
              })

              columnsToRemoveAllStores = columnsToRemoveAllStores.filter(
                col => !aux.includes(col)
              )
              aux.splice(0)
            }

            let sellersOtherInputsCount = 0

            let formattedName = name

            if (reservedNames.includes(name)) {
              formattedName = name.substring(0, 31 - 16) + '(nome duplicado)'
            } else if (
              name.length > 31 &&
              reservedNames.find(
                reservedName =>
                  reservedName.substring(0, 31 - 3) + '...' ===
                  name.substring(0, 31 - 3) + '...'
              )
            ) {
              const subNumber = (indexCount + 1).toString().length
              formattedName =
                name.substring(0, 31 - 5 - subNumber) + `...(${indexCount + 1})`
              indexCount += 1
            } else {
              if (name.length > 31) {
                formattedName = name.substring(0, 31 - 3) + '...'
              }
            }

            reservedNames.push(name)

            if (store) {
              sellersCount += sellers.length
              storesCount++

              const tableStoreData = formatTableStoreDataToExcel(store)

              // Tabela para cada loja das Lojas Oscar
              if (otherInputs) {
                const total = {
                  items: otherInputs.other.items + store.total.items,
                  sales: otherInputs.other.sales + store.total.sales,
                  sold: otherInputs.other.sold + store.total.sold
                }

                const formattedOscarStoreTable = formatTableStoreDataToExcel({
                  ...otherInputs,
                  projection: { sold: otherInputs.store.soldProjection },
                  metric: { acDayGoal: store.metric.acDayGoal },
                  total
                })

                // Remoção de alguns dados das colunas da loja das Lojas Oscar
                // % da Meta / Meta Atual / Comissão / Projeção de Comissão
                // const indexColumnsToBeModified = [4, 5, 7, 8]

                // for (let i = 0; i < indexColumnsToBeModified.length; i++) {
                //   formattedOscarStoreTable.splice(
                //     indexColumnsToBeModified[i],
                //     1,
                //     '-'
                //   )
                // }
                oscarTableStoreData = formattedOscarStoreTable
              }

              const formatSections = sections => {
                const sellerData = sections.reduce((acc, curr, index) => {
                  const {
                    salary,
                    complement,
                    fixedCommission,
                    commission,
                    dsrDays,
                    dsr,
                    bonus,
                    sumCommission
                  } = curr

                  // Check and update salary
                  if (!!salary) {
                    acc.salary ? (acc.salary += salary) : (acc.salary = salary)
                  }

                  // Check and update complement
                  if (!!complement) {
                    acc.complement
                      ? (acc.complement += complement)
                      : (acc.complement = complement)
                  }

                  // Check and update fixed commission value
                  if (!!fixedCommission?.value) {
                    acc.fixedCommission = acc.fixedCommission || {}
                    acc.fixedCommission.value
                      ? (acc.fixedCommission.value += fixedCommission.value)
                      : (acc.fixedCommission.value = fixedCommission.value)
                  }

                  // Check and update fixed commission percentage
                  if (!!fixedCommission?.percentage) {
                    acc.fixedCommission = acc.fixedCommission || {}
                    const periodString = `Período ${index + 1} (${(
                      fixedCommission.percentage * 100
                    ).toFixed(2)}%)`
                    acc.fixedCommission.percentage
                      ? (acc.fixedCommission.percentage += ` / ${periodString}`)
                      : (acc.fixedCommission.percentage = periodString)
                  }

                  // Check and update commission value
                  if (!!commission?.value) {
                    acc.commission = acc.commission || {}
                    acc.commission.value
                      ? (acc.commission.value += commission.value)
                      : (acc.commission.value = commission.value)
                  }

                  // Check and update commission percentage
                  if (!!commission?.percentage) {
                    acc.commission = acc.commission || {}
                    acc.commission.percentage
                      ? (acc.commission.percentage += commission.percentage)
                      : (acc.commission.percentage = commission.percentage)
                  }

                  // Check and update DSR days
                  if (!!dsrDays) {
                    acc.dsrDays
                      ? (acc.dsrDays += dsrDays)
                      : (acc.dsrDays = dsrDays)
                  }

                  // Check and update DSR value
                  if (!!dsr) {
                    acc.dsr ? (acc.dsr += dsr) : (acc.dsr = dsr)
                  }

                  // Check and update bonus value
                  if (!!bonus?.value) {
                    acc.bonus = acc.bonus || {}
                    acc.bonus.value
                      ? (acc.bonus.value += bonus.value)
                      : (acc.bonus.value = bonus.value)
                  }

                  // Check and update bonus percentage
                  if (!!bonus?.percentage) {
                    acc.bonus = acc.bonus || {}
                    acc.bonus.percentage
                      ? (acc.bonus.percentage += bonus.percentage)
                      : (acc.bonus.percentage = bonus.percentage)
                  }

                  // Check and update total remuneration
                  if (!!sumCommission) {
                    acc.remunerationTotal
                      ? (acc.remunerationTotal += sumCommission)
                      : (acc.remunerationTotal = sumCommission)
                  }

                  return acc
                }, {})

                return sellerData
              }

              const tableUsersData = sellers.map(rawSeller => {
                const seller = {
                  ...rawSeller,
                  commission: rawSeller.commission?.sections
                    ? formatSections(rawSeller.commission.sections)
                    : rawSeller.commission,
                  projection: rawSeller.projection?.sections
                    ? formatSections(rawSeller.projection.sections)
                    : rawSeller.projection
                }

                // Tabela para cada vendedor das Lojas Oscar
                if (seller.otherInputs) {
                  const formattedSellerOscarStoreTable =
                    formatTableSellersDataToExcel({
                      ...seller,
                      total: { ...seller.otherInputs }
                    })

                  // Remoção de alguns dados das colunas de vendedores das Lojas Oscar
                  // Meta Atual / Comissão / Projeção de Comissão
                  // const indexColumnsToBeModified = [6, 7, 8]

                  // for (let i = 0; i < indexColumnsToBeModified.length; i++) {
                  //   formattedSellerOscarStoreTable.splice(
                  //     indexColumnsToBeModified[i],
                  //     1,
                  //     '-'
                  //   )
                  // }

                  oscarTableUsersData.push(formattedSellerOscarStoreTable)
                  sellersOtherInputsCount++
                }

                // Tabela de lojas comuns
                if (columnsToRemove.length > 0) {
                  checkColumnsToRemove(seller)
                }
                return formatTableSellersDataToExcel(seller)
              })

              // Adiciona nome da loja nas colunas loja e vendedores (na tab Todas as lojas)
              const allStoresTableUserData = deepClone(tableUsersData)
              allStoresTableUserData.forEach(userRow =>
                userRow.splice(0, 0, name)
              )

              const allStoresTableStoreData = deepClone(tableStoreData)
              allStoresTableStoreData.splice(0, 0, name)

              const allStoresColumn = [
                allStoresTableStoreData,
                ...allStoresTableUserData
              ]

              // Se for a primeira iteração, adiciona o header da planilha na tab 'Todas as lojas'
              if (!hasAddedHeaderLoja) {
                const allStoresTableHeaders = [...tableHeaders]
                allStoresTableHeaders.splice(0, 0, 'Loja')
                allStoresColumn.unshift(allStoresTableHeaders)
                hasAddedHeaderLoja = true
              }

              const defaultColumnsExcel = [
                [name],
                [monthName.toUpperCase()],
                tableHeaders,
                tableStoreData,
                ...tableUsersData
              ]

              if (otherInputs) {
                defaultColumnsExcel.splice(2, 0, [
                  'Desempenho vendedores - Todas as Lojas'
                ])

                defaultColumnsExcel.push(
                  [],
                  [`Desempenho Loja - ${name}`],
                  tableHeaders,
                  oscarTableStoreData,
                  ...oscarTableUsersData
                )
              }

              allStoresTabSheet.push(allStoresColumn)

              const ws = XLSX.utils.aoa_to_sheet(defaultColumnsExcel)

              const indexSum = otherInputs ? 5 : 4
              // Lojas normais
              new Array(sellers.length + 1).fill(0).forEach((_, index) => {
                ws[`C${index + indexSum}`].z = '"R$ "#,##0.00' // Faturamento
                ws[`D${index + indexSum}`].z = '"R$ "#,##0.00' // Projeção de Faturamento
                ws[`E${index + indexSum}`].z = '"R$ "#,##0.00' // Meta Principal
                ws[`F${index + indexSum}`].z = '0.00%' // % da Meta
                // G Meta Atual
                ws[`H${index + indexSum}`].z = '"R$ "#,##0.00' // Piso
                ws[`I${index + indexSum}`].z = '"R$ "#,##0.00' // Complemento
                ws[`J${index + indexSum}`].z = '0.00%' // % Comissão Base
                ws[`K${index + indexSum}`].z = '"R$ "#,##0.00' // Comissão Base
                ws[`L${index + indexSum}`].z = '0.00%' // % de Comissão
                ws[`M${index + indexSum}`].z = '"R$ "#,##0.00' // Comissão
                ws[`N${index + indexSum}`].z = '0' // DSR
                ws[`O${index + indexSum}`].z = '"R$ "#,##0.00' // Valor DSR
                ws[`P${index + indexSum}`].z = '0.00%' // Bônus
                ws[`Q${index + indexSum}`].z = '"R$ "#,##0.00' // Valor Bônus
                ws[`R${index + indexSum}`].z = '"R$ "#,##0.00' // Remuneração Total
                ws[`S${index + indexSum}`].z = '"R$ "#,##0.00' // Projeção Remuneração
                ws[`T${index + indexSum}`].z = '0' // Nº de vendas
                ws[`U${index + indexSum}`].z = '"R$ "#,##0.00' // Ticket médio
                ws[`V${index + indexSum}`].z = '0.00' // Peça/Venda
                ws[`W${index + indexSum}`].z = '"R$ "#,##0.00' // Preço médio
                ws[`X${index + indexSum}`].z = '0' // Nº de Peças
              })

              if (otherInputs) {
                new Array(sellersOtherInputsCount + 1)
                  .fill(0)
                  .forEach((_, index) => {
                    // other inputs
                    ws[`C${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Faturamento
                    ws[`D${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Projeção de Faturamento
                    ws[`E${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Meta Principal
                    ws[`F${index + 9 + sellers.length}`].z = '0.00%' // % da Meta
                    // G Meta Atual
                    ws[`H${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Piso
                    ws[`I${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Complemento
                    ws[`J${index + 9 + sellers.length}`].z = '0.00%' // % Comissão Base
                    ws[`K${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Comissão Base
                    ws[`L${index + 9 + sellers.length}`].z = '0.00%' // % de Comissão
                    ws[`M${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Comissão
                    ws[`N${index + 9 + sellers.length}`].z = '0' // DSR
                    ws[`O${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Valor DSR
                    ws[`P${index + 9 + sellers.length}`].z = '0.00%' // Bônus
                    ws[`Q${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Valor Bônus
                    ws[`R${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Remuneração Total
                    ws[`S${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Projeção Remuneração
                    ws[`T${index + 9 + sellers.length}`].z = '0' // Nº de vendas
                    ws[`U${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Ticket médio
                    ws[`V${index + 9 + sellers.length}`].z = '0.00' // Peça/Venda
                    ws[`W${index + 9 + sellers.length}`].z = '"R$ "#,##0.00' // Preço médio
                    ws[`X${index + 9 + sellers.length}`].z = '0' // Nº de Peças
                  })
              }

              if (columnsToRemove.length > 0) {
                const columnsToRemoveIndex = columnsToRemove.map(col =>
                  tableHeaders.findIndex(header => header === col)
                )
                const columnsLetter = columnsToRemoveIndex.map(col =>
                  String.fromCharCode(col + 1 + 64)
                )

                Object.entries(ws).forEach(([key]) => {
                  const column = key[0]

                  if (columnsLetter.includes(column)) {
                    delete ws[key]
                  }
                })
              }

              const newWs = fillObjectGaps(ws)

              XLSX.utils.book_append_sheet(wb, newWs, formattedName)
            } else {
              const ws = XLSX.utils.aoa_to_sheet([
                [name.toUpperCase()],
                [monthName.toUpperCase()],
                ['Sem meta cadastrada para esse mês.']
              ])
              XLSX.utils.book_append_sheet(wb, ws, formattedName)
            }
          }
        )

        const ws = XLSX.utils.aoa_to_sheet([...allStoresTabSheet.flat(1)])

        new Array(sellersCount + storesCount + 1)
          .fill(0)
          .forEach((_, index) => {
            ws[`D${index + 1}`].z = '"R$ "#,##0.00' // Faturamento
            ws[`E${index + 1}`].z = '"R$ "#,##0.00' // Projeção de Faturamento
            ws[`F${index + 1}`].z = '"R$ "#,##0.00' // Meta Principal
            ws[`G${index + 1}`].z = '0.00%' // % da Meta
            // H Meta Atual
            ws[`I${index + 1}`].z = '"R$ "#,##0.00' // Piso
            ws[`J${index + 1}`].z = '"R$ "#,##0.00' // Complemento
            ws[`K${index + 1}`].z = '0.00%' // % Comissão Base
            ws[`L${index + 1}`].z = '"R$ "#,##0.00' // Comissão Base
            ws[`M${index + 1}`].z = '0.00%' // % de Comissão
            ws[`N${index + 1}`].z = '"R$ "#,##0.00' // Comissão
            ws[`O${index + 1}`].z = '0' // DSR
            ws[`P${index + 1}`].z = '"R$ "#,##0.00' // Valor DSR
            ws[`Q${index + 1}`].z = '0.00%' // Bônus
            ws[`R${index + 1}`].z = '"R$ "#,##0.00' // Valor Bônus
            ws[`S${index + 1}`].z = '"R$ "#,##0.00' // Remuneração Total
            ws[`T${index + 1}`].z = '"R$ "#,##0.00' // Projeção Remuneração
            ws[`U${index + 1}`].z = '0' // Nº de vendas
            ws[`V${index + 1}`].z = '"R$ "#,##0.00' // Ticket médio
            ws[`W${index + 1}`].z = '0.00' // Peça/Venda
            ws[`X${index + 1}`].z = '"R$ "#,##0.00' // Preço médio
            ws[`Y${index + 1}`].z = '0' // Nº de Peças
          })

        if (columnsToRemoveAllStores.length > 0) {
          const columnsToRemoveIndex = columnsToRemoveAllStores.map(col =>
            tableHeaders.findIndex(header => header === col)
          )
          const columnsLetter = columnsToRemoveIndex.map(col =>
            String.fromCharCode(col + 1 + 64)
          )

          Object.entries(ws).forEach(([key]) => {
            const column = key[0]

            if (columnsLetter.includes(column)) {
              delete ws[key]
            }
          })
        }

        const newWs = fillObjectGaps(ws)

        XLSX.utils.book_append_sheet(wb, newWs, 'Todas as lojas')

        // Removemos a tab 'Todas as lojas' que está no final pra poder adicionar no inicio das tabs
        const allStoresColumnIndex = wb.SheetNames.indexOf('Todas as lojas')
        wb.SheetNames.splice(allStoresColumnIndex, 1)
        wb.SheetNames.unshift('Todas as lojas')

        const monthNameWithYear = `${getMonthName(selectedMonth)}_${getYear(
          new Date(new Date(selectedMonth))
        )}`

        const storesData = {}

        response.data.forEach(store => {
          const storeName = store.name

          if (!storesData[storeName]) {
            storesData[storeName] = {}
          }

          store.store.daysGoal.forEach(day => {
            const formattedDate = DateTime.fromISO(day.date, {
              locale: 'pt-BR'
            })
              .toFormat('dd/MMM')
              .toLowerCase()
              .replace('.', '')

            storesData[storeName][formattedDate] = parseFloat(
              day.goal.toFixed(2)
            )
          })
        })

        const allDates = new Set()
        response.data.forEach(store =>
          store.store.daysGoal.forEach(day => {
            const formattedDate = DateTime.fromISO(day.date, {
              locale: 'pt-BR'
            })
              .toFormat('dd/MMM')
              .toLowerCase()
              .replace('.', '')
            allDates.add(formattedDate)
          })
        )

        const sortedDates = [...allDates].sort()
        const excelData = [[null, ...sortedDates]]

        const totalRow = ['Total', ...sortedDates.map(() => 0)]

        Object.keys(storesData).forEach(storeName => {
          const row = [storeName]

          sortedDates.forEach((date, index) => {
            const value = storesData[storeName][date] ?? 0
            row.push(value)

            totalRow[index + 1] += value
          })

          excelData.push(row)
        })

        excelData.splice(1, 0, totalRow)

        const dailyGoalsSheet = XLSX.utils.aoa_to_sheet(excelData)

        XLSX.utils.book_append_sheet(wb, dailyGoalsSheet, 'Metas diárias')

        const allStoresColumnIndexx = wb.SheetNames.indexOf('Metas diárias')
        wb.SheetNames.splice(allStoresColumnIndexx, 1)
        wb.SheetNames.splice(1, 0, 'Metas diárias')

        console.log(wb.SheetNames.length)
        XLSX.writeFile(
          wb,
          `[NEOPRO] Relatorio-Lojas-${monthNameWithYear}.xlsx`,
          {
            bookType: 'xlsx',
            type: 'binary'
          }
        )
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        toast.error('Houve um erro ao tentar gerar a planilha')
        setIsLoading(false)
      }
    }
  }

  return (
    <SheetModal open={isOpen} onClose={closeModal}>
      <div className='content'>
        <h1>Qual mês você deseja exportar?</h1>
        <span>Selecione abaixo o mês desejado</span>

        <div className='close-button' onClick={closeModal}>
          <CloseTwoTone style={{ color: 'lightgrey' }} />
        </div>

        <div className='checkbox-container'>
          <FormControl>
            <InputLabel>Mês</InputLabel>
            <Select
              value={selectedMonth}
              onChange={event => setSelectedMonth(`${event.target.value}`)}
              MenuProps={MenuProps}
              style={{
                width: '150px'
              }}
            >
              {months.map(month => {
                const monthName = `${getMonthName(month)} / ${getYear(
                  new Date(month)
                )}`
                return (
                  <MenuItem key={month} value={month}>
                    <ListItemText primary={monthName} />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </div>

        <SheetButton
          disabled={isLoading}
          onClick={handleExportAllSpreadSheet}
          isModalButton
        >
          {isLoading ? (
            <>
              <CircularProgress
                size={24}
                color='inherit'
                style={{ marginRight: 12 }}
              />
              Baixando....
            </>
          ) : (
            <>
              <CloudDownloadTwoTone style={{ marginRight: 12 }} />
              Fazer download da planilha
            </>
          )}
        </SheetButton>
      </div>
    </SheetModal>
  )
}
